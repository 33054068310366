// TermsAndConditions.js
import React from "react";
import styles from "./TermsAndConditions.module.css"; // Import CSS module for styling
import Layout from "../Componets/Layout/Layout";

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <h2 className={styles.heading}>
          <b>Terms and Conditions</b>
        </h2>

        {/* User Account, Password, and Security */}
        <div className={styles.section}>
          <h3>User Account, Password, and Security</h3>
          <p>
            Access to the Akiyara's Cosmetics website or app is granted only to
            individuals capable of forming legally binding contracts under
            applicable law. Minors under the age of 18 may use the platform
            under parental supervision. User accounts are created upon
            registration, and users are responsible for maintaining the
            confidentiality of their account information. Any unauthorized use
            should be reported immediately to Akiyara's Cosmetics.
          </p>
          <p>
            By registering on Akiyara's Cosmetics platform, users consent to
            receive marketing and promotional communications via SMS, WhatsApp,
            email, and other notification methods.
          </p>
        </div>

        {/* Product Use & Services */}
        <div className={styles.section}>
          <h3>Product Use & Services</h3>
          <p>
            Products and services provided by Akiyara's Cosmetics are for
            personal and/or professional use only and shall not be sold or
            resold for commercial purposes. Consumers with allergies are advised
            to review product ingredient statements before use.
          </p>
        </div>

        {/* Pricing and Offer Information */}
        <div className={styles.section}>
          <h3>Pricing and Offer Information</h3>
          <p>
            Akiyara's Cosmetics strives for accurate product and pricing
            information, but errors may occur. Prices and availability are
            subject to change without prior notice. Offers and promotions may be
            withdrawn without notice.
          </p>
        </div>

        {/* Order Placement Limit */}
        <div className={styles.section}>
          <h3>Order Placement Limit</h3>
          <p>
            Individual buyers may place a maximum of 7 orders or reach a
            cumulative total payable amount within a single calendar month.
            Exceeding this limit requires contacting Akiyara's Cosmetics
            directly.
          </p>
        </div>

        {/* Payments */}
        <div className={styles.section}>
          <h3>Payments</h3>
          <p>
            Various modes of payment are accepted, including UPI, Credit Cards,
            Debit Cards, Net Banking, Wallets, e-Gift cards, and reward points.
            Online payments are recommended for faster processing. Cash On
            Delivery is available for limited areas.
          </p>
        </div>

        {/* Mobikwik Wallet Offer */}
        <div className={styles.section}>
          <h3>Mobikwik Wallet Offer</h3>
          <p>
            Terms and conditions apply to Mobikwik Wallet offers, including
            minimum transaction amounts, coupon codes, and validity periods.
            Cashback is subject to Mobikwik's terms and conditions.
          </p>
        </div>

        {/* Shipping/Delivery Policy */}
        <div className={styles.section}>
          <h3>Shipping/Delivery Policy</h3>
          <p>
            Confirmed orders are shipped within 24 to 48 working hours and
            delivered within 2-5 working days. Delivery fees may apply based on
            order value.
          </p>
        </div>

        {/* Cancellation of Unusual Orders */}
        <div className={styles.section}>
          <h3>Cancellation of Unusual Orders</h3>
          <p>
            Akiyara's Cosmetics reserves the right to cancel orders at its
            discretion, such as due to pricing errors or fraudulent activity.
            Customers will be notified accordingly.
          </p>
        </div>

        {/* Refunds/Cancellations */}
        <div className={styles.section}>
          <h3>Refunds/Cancellations</h3>
          <p>
            If you are not satisfied with a product or service, you may request
            a refund or cancellation. Refunds for canceled orders will be
            processed within 5 to 7 working days. The refund amount will be
            credited to the customer's bank account based on the original
            payment mode.
          </p>
        </div>

        {/* Privacy Policy */}
        <div className={styles.section}>
          <h3>Privacy Policy</h3>
          <p>
            Akiyara's Cosmetics respects your privacy and is committed to
            protecting your personal data. Our Privacy Policy explains how we
            collect, use, and protect your information. Personal data handling
            practices comply with applicable laws and regulations. Users consent
            to the collection and use of their information as outlined in the
            Privacy Policy.
          </p>
        </div>

        {/* Terms and Conditions for FAQs */}
        <div className={styles.section}>
          <h3>Terms and Conditions for FAQs</h3>
          <p>
            Specific terms and conditions apply to FAQ offers, including product
            eligibility, offer stacking, and limitations on complimentary gifts.
            Clearance section products may be near their recommended shelf life
            expiry.
          </p>
        </div>

        {/* Grievance Officer */}
        <div className={styles.section}>
          <h3>Grievance Officer</h3>
          <p>
            A designated Grievance Officer handles complaints and can be
            contacted via email at akiyarass@gmail.com or phone at +91 8080401501 during specified
            hours.
          </p>
        </div>

        {/* Copyright and Intellectual Property */}
        <div className={styles.section}>
          <h3>Copyright and Intellectual Property</h3>
          <p>
            All content on the Akiyara's Cosmetics platform is protected by
            copyright and intellectual property laws. Modification,
            reproduction, or commercial exploitation of content is prohibited
            without authorization.
          </p>
        </div>

        {/* Contact Information */}
        <div className={styles.section}>
          <h3>Contact Information</h3>
          <p>
            For any inquiries regarding these Terms and Conditions, users can
            contact Akiyara's Cosmetics via email at akiyarass@gmail.com or phone at +91 8080401501.
         
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
