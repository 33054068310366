import React, { useEffect } from "react";
import { FaFireFlameCurved } from "react-icons/fa6";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";
import { Dropdown, Collapse, initMDB } from "mdb-ui-kit";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { Badge } from "antd";
import { useCart } from "../../contex/cart";
const Navbar = () => {
  const [cart] = useCart();
  useEffect(() => {
    // Call initMDB with required components
    initMDB({ Dropdown, Collapse });
  }, []); // Ensure it runs only once on component mount

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-body-tertiary">
        {/* Container wrapper */}
        <div className="container-fluid">
          {/* Toggle button */}
          <button
            data-mdb-collapse-init
            class="navbar-toggler"
            type="button"
            data-mdb-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>
          {/* Collapsible wrapper */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Navbar brand */}
            {/* Left links */}
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link ml-3">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/allproducts" className="nav-link">
                  All Products
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/trending" className="nav-link">
                  Trending
                  <FaFireFlameCurved
                    style={{ marginLeft: "3px", color: "#ff0000" }}
                  />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/upcomming" className="nav-link ml-3">
                  Upcomming
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about" className="nav-link">
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/faq" className="nav-link">
                  FAQ's
                </NavLink>
              </li>
            </ul>
            {/* Left links */}
          </div>
          {/* Collapsible wrapper */}
          {/* Right elements */}
          <div className="d-flex align-items-center">
            {/* Icon */}

            <Badge
              count={cart?.length}
              showZero
              style={{ fontSize: "10px", padding: "0 4px", margin: "-0px" }}
            >
              <NavLink to="/cart" className="text-reset me-3">
                <i className="fas fa-shopping-cart" />
              </NavLink>
            </Badge>
          </div>
          {/* Right elements */}
        </div>
        {/* Container wrapper */}
      </nav>
      {/* Navbar */}
    </div>
  );
};

export default Navbar;
