import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import AllProducts from "./pages/AllProducts";
import Contact from "./pages/Contact";
import Pagenotfound from "./pages/Pagenotfound";

import Trending from "./pages/Trending";
import Upcomming from "./pages/Upcomming";


import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/user/Dashboard";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Register from "./pages/Auth/Register";
import PrivateRoute from "./Componets/Routes/Private";
import Orders from "./pages/user/Order";
import Profile from "./pages/user/Profile";
import Users from "./pages/Admin/Users";
import CreateProduct from "./pages/Admin/CreateProduct";
import AdminRoute from "./Componets/Routes/AdminRoutes";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import CreateCategory from "./pages/Admin/CreateCategory";
import UpdateProduct from "./pages/Admin/UpdateProduct";
import Products from "./pages/Admin/Products";
import Search from "./pages/Search";
import ProductDetails from "./pages/ProductDetails";
import CartPage from "./pages/CartPage";
import { AuthProvider } from "./contex/auth";
import AdminOrder from "./pages/Admin/AdminOrder";
import CreateBanner from "./pages/Admin/CreateBanner";
import Banners from "./pages/Admin/Banners";
import UpdateBanner from "./pages/Admin/UpdateBanner";
import FAQ from "./pages/Faq";
import ProductCard from "./pages/ProductCard";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";



const App = () => {
  return (
    <>
     
      
     <AuthProvider>
      <Routes>
     
        <Route path="/" element={<HomePage></HomePage>}></Route>
        <Route path="/product/:slug" element={<ProductDetails></ProductDetails>}></Route>
        <Route path="/about" element={<About></About>}></Route>
        <Route
            path="/termsandconditions"
            element={<TermsAndConditions />}
          ></Route>
        <Route path="/upcomming" element={<Upcomming></Upcomming>}></Route>
        <Route path="/cart" element={<CartPage></CartPage>}></Route>
        <Route path="/search" element={<Search></Search>}></Route>
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="user" element={<Dashboard />} />
          <Route path="user/orders" element={<Orders />} />
          <Route path="user/profile" element={<Profile />} />
        </Route>
        <Route path="/faq" element={<FAQ/>}></Route>
        <Route
          path="/allproducts"
          element={<AllProducts></AllProducts>}
        ></Route>
     
     <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/create-category" element={<CreateCategory />} />
          <Route path="admin/create-product" element={<CreateProduct />} />
          <Route path="admin/product/:slug" element={<UpdateProduct />} />
          <Route path="admin/products" element={<Products />} />
          <Route path="admin/banner/:slug" element={<UpdateBanner/>} />
          <Route path="admin/banners" element={<Banners/>} />
          <Route path="admin/users" element={<Users />} />
          <Route path="admin/adminOrder" element={<AdminOrder />} />
          <Route path="admin/create-banner" element={<CreateBanner />} />
          
        </Route>




        <Route path="/contact" element={<Contact></Contact>}></Route>
        <Route path="/policy" element={<PrivacyPolicy/>}></Route>
        <Route path="/trending" element={<Trending></Trending>}></Route>
        <Route path="/register" element={<Register></Register>}></Route>

        <Route path="/login" element={<Login></Login>}></Route>
        <Route
          path="/forgot-password"
          element={<ForgotPassword></ForgotPassword>}
        ></Route>

        <Route path="/*" element={<Pagenotfound></Pagenotfound>}></Route>
        
      </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
