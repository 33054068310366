import React from "react";
import Layout from "../Componets/Layout/Layout";
import { useSearch } from "../contex/search";
import { useCart } from "../contex/cart";
import { useAuth } from "../contex/auth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const navigate=useNavigate()
  const [values, setValues] = useSearch();
  const [cart,setCart]=useCart()
  const [auth] = useAuth();

  const convert = require('number-to-words');

  function convertPriceToWords(price) {
      // Split the price into rupees and paise
      const [rupees, paise] = price.toString().split('.');
  
      // Convert rupees to words
      const rupeesInWords = convert.toWords(parseInt(rupees, 10));
  
      // Convert paise to words
      const paiseInWords = paise ? `${convert.toWords(parseInt(paise, 10))} Paise` : '';
  
      // Construct the final representation
      return `${rupeesInWords} Rupees ${paiseInWords}`;
  }
  return (
    <Layout title={"Search results"}>
      <div className="container">
        <div className="text-center">
          <h1>Search Resuts</h1>
       
          <div className="product-container">
        {values?.results.map((product) => (
         
            <div className="product-card" key={product.id}>
              <div className="logo-cart">
                <i className="bx bx-shopping-bag" />
              </div>
              <div className="main-images">
                <img
                  id="MDB-logo"
                  src={`https://backend-sepia-nine-81.vercel.app/api/v1/product/product-photo/${product._id}`}
                  alt={product.name}
                  draggable="false"
                  height={50}
                />
              </div>
              <div className="shoe-details">
                <span className="shoe_name">{product.name}</span>
                <p>{product.description.substring(0,60)}...</p>
                <div className="stars">
                  <i className="bx bxs-star" />
                  <i className="bx bxs-star" />
                  <i className="bx bxs-star" />
                  <i className="bx bxs-star" />
                  <i className="bx bx-star" />
                </div>
              </div>
              <div className="color-price">
                <div className="price">
                <span className="price_num">₹{product.price}</span>
                <span className="price_letter">{convertPriceToWords(product.price)}</span>
                </div>
              </div>
              <div className="button-container">
              <button className="more-details" onClick={()=>navigate(`/product/${product.slug}`)} >More Details</button>
              <button className="add-to-cart" onClick={() => {
                    if (!auth.token) {
                      toast.error('Please log in to add items to the cart.');
                      navigate('/login');
                      return;
                    }
                      setCart([...cart, product]);
                      localStorage.setItem(
                        "cart",
                        JSON.stringify([...cart, product])
                      );
                      toast.success("Item Added to cart");
                    }}>Add To Cart</button>
              </div>
            </div>
          ))}
     
             </div>
        </div>
      </div>
    </Layout>
  );
};

export default Search;