import React from "react";
import { NavLink } from "react-router-dom";
const AdminMenu = () => {
  return (
    <>
      <div className="text-center">
        <div className="list-group">
          <h4>Admin Panel</h4>
          <NavLink
            to="/dashboard/admin/create-category"
            className="list-group-item list-group-item-action"
          >
            Create Category
          </NavLink>
          <NavLink
            to="/dashboard/admin/create-product"
            className="list-group-item list-group-item-action"
          >
            Create Product
          </NavLink>
          <NavLink

          to="/dashboard/admin/create-banner"
            className="list-group-item list-group-item-action"
          >
            Create Banner
          </NavLink>

          <NavLink
          to="/dashboard/admin/products"
            className="list-group-item list-group-item-action"
          >
            Products
          </NavLink>
          <NavLink
           to="/dashboard/admin/banners"
            className="list-group-item list-group-item-action"
          >
            Banner
          </NavLink>
          
         
         
          <NavLink
            to="/dashboard/admin/users"
            className="list-group-item list-group-item-action"
          >
            Users
          </NavLink>

          <NavLink
            to="/dashboard/admin/adminOrder"
            className="list-group-item list-group-item-action"
          >
            All Orders
          </NavLink>

          
        </div>
      </div>
    </>


//  to="/dashboard/admin/products"
//    to="/dashboard/admin/banners"
  );
};

export default AdminMenu;