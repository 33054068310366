import React from "react";
import Layout from "../Componets/Layout/Layout";
import styles from "./About.module.css"; // Import custom CSS for the About page

const About = () => {
  return (
    <Layout title="About Us">
      <div className={styles["about-page"]}>
        <div className={styles["hero-section"]}>
          <h1>Welcome to Akiyaras Cosmetics</h1>
          <p>Your one-stop shop for all your cosmetic needs</p>
        </div>

        <div className="container mt-5">
          <h2>About Us</h2>
          <p>
            Welcome to Akiyaras Cosmetics! We are dedicated to providing the best products
            and services to our customers. Here you can find details about our
            shop.
          </p>

          <div className="row mt-4">
            <div className="col-md-6">
              <h3>Our Outlet</h3>
              <p>
                Shop no- 5, Basement, Pragat Complex, Main Road, Kopargaon,
                Maharashtra 423601
              </p>
              <p>Email: akiyarass@gmail.com</p>
              <p>Phone: 8080401501</p>
            </div>

            <div className="col-md-6">
              <h3>Find Us Here</h3>
              <div className={styles["map-responsive"]}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d236.938629549849!2d74.47937999954442!3d19.884949000284966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdc45516978edb9%3A0xb49b26e070650de7!2sShree%20T-shirt%20Hub!5e1!3m2!1sen!2sin!4v1718747590694!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  title="shop-location"
                ></iframe>
              </div>
            </div>
          </div>

          <h2 className="mt-5">About Akiyaras Cosmetics</h2>
          <p>
            Our shop was founded in 2022 with the providing quality cosmetics
            products at affordable prices. We believe in customer satisfaction
            and strive to offer the best shopping experience possible.
          </p>
          <p>
            We are located in the heart of Anytown, making it easy for you to
            visit us. Our friendly staff are always ready to assist you with any
            queries or help you might need.
          </p>
          <p>
            Thank you for choosing our shop. We look forward to serving you!
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default About;
