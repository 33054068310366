import React, { useState } from "react";
import Layout from "../../Componets/Layout/Layout";
import AdminMenu from "../../Componets/Layout/AdminMenu";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdCheckCircle } from "react-icons/md";

const CreateBanner = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [photo, setPhoto] = useState(null);

  // Handle creating a banner
  const handleCreate = async (e) => {
    e.preventDefault();
    // Check if name and photo are provided
    if (!name || !photo) {
      toast.error("Please provide both name and photo.");
      return;
    }
    try {
      const productData = new FormData();
      productData.append("name", name);
      productData.append("photo", photo);
      const { data } = await axios.post(
        "https://backend-sepia-nine-81.vercel.app/api/v1/banner/create-banner",
        productData
      );
      if (data?.success) {
        toast.success("Banner Created Successfully", {
          icon: <MdCheckCircle size={24} />,
        });
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(
        "Something went wrong(Check the size of the banner, should be less than 1mb)"
      );
    }
  };

  return (
    <Layout title={"Dashboard - Create Product"}>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1>Create Banner</h1>
            <div className="m-1 w-75">
              <div className="mb-3">
                <label className="btn btn-outline-secondary col-md-12">
                  {photo ? photo.name : "Upload Photo"}
                  <input
                    type="file"
                    name="photo"
                    accept="image/*"
                    onChange={(e) => setPhoto(e.target.files[0])}
                    hidden
                  />
                </label>
              </div>
              <div className="mb-3">
                {photo && (
                  <div className="text-center">
                    <img
                      src={URL.createObjectURL(photo)}
                      alt="product_photo"
                      height={"200px"}
                      className="img img-responsive"
                    />
                  </div>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  value={name}
                  placeholder="write a name"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <button className="btn btn-primary" onClick={handleCreate}>
                  CREATE Banner
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateBanner;
