import React, { useState, useEffect } from "react";
import AdminMenu from "../../Componets/Layout/AdminMenu";
import Layout from "../../Componets/Layout/Layout";
import axios from "axios";
import { FaWhatsapp } from "react-icons/fa";

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get(
          "https://backend-sepia-nine-81.vercel.app/api/users"
        );
        setUsers(data);
      } catch (error) {}
    };

    fetchUsers();
  }, []);

  const formatPhoneNumber = (phone) => {
    // Remove any non-numeric characters
    const cleanedPhone = phone.replace(/\D/g, "");
    // Prepend the Indian country code
    return `91${cleanedPhone}`;
  };

  return (
    <Layout title={"Dashboard - All Users"}>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1>All Users</h1>
            <table className="table table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>WhatsApp</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user._id}>
                    <td>{user._id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td className="text-center align-middle">
                      {" "}
                      {/* Added classes */}
                      <a
                        href={`https://wa.me/${formatPhoneNumber(user.phone)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaWhatsapp
                          style={{ fontSize: "24px", color: "green" }}
                        />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Users;
