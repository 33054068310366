import React from "react";
import Layout from '../Componets/Layout/Layout';
import { useCart } from "../contex/cart";
import { useAuth } from "../contex/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const CartPage = () => {
  const [auth] = useAuth();
  const [cart, setCart] = useCart();
  const navigate = useNavigate();
  let amount;

  // Total price
  const totalPrice = () => {
    try {
      let total = 0;
      cart?.forEach((item) => {
        total += item.price;
      });
      amount = total;
      return total.toLocaleString("en-US", {
        style: "currency",
        currency: "INR",
      });
    } catch (error) {

    }
  };

  // Delete item
  const removeCartItem = async (pid) => {
    try {
      let myCart = [...cart];
      let index = myCart.findIndex((item) => item._id === pid);
      myCart.splice(index, 1);
      setCart(myCart);
      localStorage.setItem("cart", JSON.stringify(myCart));

      // Call the API to delete the product from the cart for the current user
      await axios.delete(`https://backend-sepia-nine-81.vercel.app/api/cart/product/${pid}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      toast.success('Product removed from cart');
    } catch (error) {
      toast.success('Product removed from cart');
    }
  };

  const checkoutHandler = async (amount) => {
    try {
      const { data: { key } } = await axios.get("https://backend-sepia-nine-81.vercel.app/api/getkey");

      const { data: { order } } = await axios.post("https://backend-sepia-nine-81.vercel.app/api/checkout", { amount });

      const options = {
        key,
        amount: order.amount,
        currency: "INR",
        name: "Akiyara's",
        description: "RazorPay",
        image: "",
        order_id: order.id,
        callback_url: "https://backend-sepia-nine-81.vercel.app/api/paymentverification",
        prefill: {
          name: auth?.user?.name || "Guest",
          email: auth?.user?.email || "guest@example.com",
          contact: "8788246176"
        },
        notes: {
          address: "Razorpay Corporate Office"
        },
        theme: {
          color: "#AA336A"
        },
        handler: async function (response) {
          // On successful payment, call the API to save the order details
          await axios.post('https://backend-sepia-nine-81.vercel.app/api/orders', {
            products: cart,
            paymentId: response.razorpay_payment_id
          }, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          });
          toast.success("Payment successful! Order placed.");
          // navigate('user/orders');
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {

      toast.error("Payment failed. Please try again.");
    }
  };

  const a = totalPrice();
  amount = a;

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center bg-light p-2 mb-1">
              {`Hello ${auth?.token && auth?.user?.name}`}
            </h1>
            <h4 className="text-center">
              {cart?.length
                ? `You Have ${cart.length} items in your cart ${
                    auth?.token ? "" : "please login to checkout"
                  }`
                : <h3 >Your Cart Is Empty</h3>}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            {cart?.map((p) => (
              <div className="row mb-2 p-3 card flex-row" key={p._id}>
                <div className="col-md-4">
                  <img
                    src={`https://backend-sepia-nine-81.vercel.app/api/v1/product/product-photo/${p._id}`}
                    className="card-img-top"
                    alt={p.name}
                    width="100px"
                    height={"100px"}
                  />
                </div>
                <div className="col-md-8">
                  <p>{p.name}</p>
                  {/* Add a check for description */}
                  <p>{p.description && p.description.substring(0, 30)}</p>
                  <p>Price :  ₹{p.price}</p>

                  <button
                    className="btn btn-danger"
                    onClick={() => removeCartItem(p._id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-4 text-center">
            <h2>Cart Summary</h2>
            <p>Total | Checkout | Payment</p>
            <hr />
            <h4>Total : {totalPrice()} </h4>
            {auth?.user?.address ? (
              <>
                <div className="mb-3">
                  <h4>Current Address</h4>
                  <h5>{auth?.user?.address}</h5>
                  <button
  className="btn btn-outline-warning"
  onClick={() => navigate("/dashboard/user/profile")}
  style={{ marginRight: "10px" }} // Adjust the value as needed
>
  Update Address
</button>

                  <button
                    className="btn btn-primary"
                    onClick={() => checkoutHandler(amount)}
                    disabled={!cart?.length}
                  >
                    Buy Now
                  </button>
                </div>
              </>
            ) : (
              <div className="mb-3">
                {auth?.token ? (
                  <button
                    className="btn btn-outline-warning"
                    onClick={() => navigate("/dashboard/user/profile")}
                  >
                    Update Address
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-warning"
                    onClick={() =>
                      navigate("/login", {
                        state: "/cart",
                      })
                    }
                  >
                    Please Login to checkout
                  </button>
                )}
              </div>
            )}
            {!cart?.length && (
              <div className="alert alert-warning" role="alert">
                Please add some products to the cart to proceed with the checkout.
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CartPage;
