import React, { useEffect, useState } from "react";
import Layout from "../Componets/Layout/Layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCart } from "../contex/cart";
import toast from "react-hot-toast";
import { useAuth } from "../contex/auth";
import { IoBagCheckSharp } from "react-icons/io5";
const AllProducts = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [cart, setCart] = useCart();
  const [auth] = useAuth();

  const getAllProducts = async () => {
    try {
      const { data } = await axios.get(
        "https://backend-sepia-nine-81.vercel.app/api/v1/product/get-product"
      );
      setProducts(data.products);
    } catch (error) {}
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const handleAddToCart = async (product) => {
    try {
      if (!auth.token) {
        toast.error("Please log in to add items to the cart.");
        navigate("/login");
        return;
      }

      const buyer = auth.user._id;
      setCart([...cart, product]);
      localStorage.setItem("cart", JSON.stringify([...cart, product]));

      const { data } = await axios.post(
        "https://backend-sepia-nine-81.vercel.app/api/cart",
        {
          products: [product],
          buyer,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      toast.success("Item added to cart");
    } catch (error) {
      toast.error("Failed to add item to cart");
    }
  };

  const convert = require("number-to-words");

  function convertPriceToWords(price) {
    // Split the price into rupees and paise
    const [rupees, paise] = price.toString().split(".");

    // Convert rupees to words
    const rupeesInWords = convert.toWords(parseInt(rupees, 10));

    // Convert paise to words
    const paiseInWords = paise
      ? `${convert.toWords(parseInt(paise, 10))} Paise`
      : "";

    // Construct the final representation
    return `${rupeesInWords} Rupees ${paiseInWords}`;
  }

  return (
    <Layout>
      <div className="product-container">
        {products.map((product) => (
          <div className="product-card" key={product._id}>
            {/* Your product card JSX */}
            <div className="logo-cart">
              <i className="bx bx-shopping-bag" />
            </div>
            <div className="main-images">
              <IoBagCheckSharp size={20} />
              <img
                id="MDB-logo"
                src={`https://backend-sepia-nine-81.vercel.app/api/v1/product/product-photo/${product._id}`}
                alt={product.name}
                draggable="false"
                height={50}
              />
            </div>
            <div className="shoe-details">
              <span className="shoe_name">
                {product.name.substring(0, 18)}...
              </span>
              <p>{product.description.substring(0, 60)}...</p>
              <div className="stars">
                <i className="bx bxs-star" />
                <i className="bx bxs-star" />
                <i className="bx bxs-star" />
                <i className="bx bxs-star" />
                <i className="bx bx-star" />
              </div>
            </div>
            <div className="color-price">
              <div className="price">
                <span className="price_num">₹{product.price}</span>
                <span className="price_letter">
                  {convertPriceToWords(product.price)}
                </span>
              </div>
            </div>
            <div className="button-container">
              <button
                className="more-details"
                onClick={() => navigate(`/product/${product.slug}`)}
              >
                More Details
              </button>
              <button
                className="add-to-cart"
                onClick={() => handleAddToCart(product)}
              >
                Add To Cart
              </button>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default AllProducts;
