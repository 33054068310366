import React, { useState, useEffect } from "react";
import Layout from "../../Componets/Layout/Layout";
import AdminMenu from "../../Componets/Layout/AdminMenu";
import toast from "react-hot-toast";
import axios from "axios";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
const { Option } = Select;

const handleDeleteAll = async () => {
  try {
    const response = await axios.delete(
      "https://backend-sepia-nine-81.vercel.app/api/banners/delete-all"
    );
    alert(response.data.message);
  } catch (error) {
    alert("Failed to delete products");
  }
};

const UpdateBanner = () => {
  const navigate = useNavigate();

  return (
    <Layout title={"Dashboard - Create Product"}>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1>Delete Banner</h1>
            <div className="m-1 w-75">
              <div className="mb-3">
                <button className="btn btn-danger" onClick={handleDeleteAll}>
                  DELETE All Banners
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpdateBanner;
