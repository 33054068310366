import React from 'react';
import styles from './TermsAndConditions.module.css';
import Layout from '../Componets/Layout/Layout';

const PrivacyPolicy = () => {
  return (
   <Layout>
    <div className={styles.container}>
      <div className={styles.heading}>Privacy Policy for Akiyaras Cosmetics</div>

      <div className={styles.section}>
        <h3>Introduction</h3>
        <p>
          This Privacy Policy outlines Akiyaras Cosmetics’ approach to data protection and privacy to fulfill its obligations under applicable laws and regulations. This Privacy Policy applies to your Personal Data which is processed by us, whether in physical or electronic mode.
        </p>
        <p>
          By visiting our website, akiyaras.com (the “Platform”), or providing your information, you expressly agree to be bound by this Privacy Policy and agree to be governed by the laws of India, including but not limited to the laws applicable to data protection and privacy. If you do not agree, please do not use or access our Platform.
        </p>
        <p>
          In this Privacy Policy, the expressions ‘Personal Data’, ‘Data Subject’, ‘Controller’, ‘Processor’, and ‘Processing’ shall have the meanings given to them in the applicable privacy laws.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Who We Are</h3>
        <p>
          Akiyaras Cosmetics is a company engaged in the business of selling, marketing, and retailing cosmetic and beauty products (“Business”) through its website, akiyaras.com.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Roles We Play</h3>
        <p>
          We play the role of a Data Controller when we collect and process Personal Data about you.
        </p>
        <p>
          We play the role of a Data Processor when we collect and process Personal Data on behalf of another Data Controller.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Our Commitment</h3>
        <p>
          We commit to protecting your privacy and hence our Personal Data handling practices are continually reviewed to ensure compliance with the applicable privacy laws and regulations.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Personal Information Gathered by Akiyaras Cosmetics</h3>
        <p>
          The information we learn and gather from you, personal or otherwise, is used to register you, verify your identity to permit you to use our Platform, undertake transactions (including to facilitate and process payments), communicate with you, convey any promotional offers, services or updates, and generally maintain your accounts with us. We also use this information to customize your experience and improve Akiyaras Cosmetics.
        </p>
        <h4>Information You Give Us:</h4>
        <p>
          We receive and store any information you provide while using Akiyaras Cosmetics, or give us in any other way. You can choose not to provide certain information, but then you might not be able to use Akiyaras Cosmetics. We use the information that you provide for such purposes as opening your account, processing your transactions, responding to your requests, and communicating with you.
        </p>
        <h4>Information We Collect About You:</h4>
        <p>
          We receive and store certain types of information whenever you interact with us. For example, like many websites, we use "cookies," and we obtain certain types of information when your web browser accesses our services. We may also receive/store information about your location and your mobile device, including a unique identifier for your device. We may use this information for internal analysis and to provide you with location-based services, such as advertising, search results, and other personalized content.
        </p>
        <h4>Information From Other Sources:</h4>
        <p>
          We might receive information about you from other sources, such as updated delivery and address information from our carriers, which we use to correct our records and deliver your next purchase more easily.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Categories of Personal Data</h3>
        <h4>Demographic & Identity Data:</h4>
        <ul>
          <li>Contact details such as name, email address, contact number, shipping address, country, date of birth, profile picture.</li>
          <li>Open data and public records such as information about YOU that is openly available on the internet.</li>
          <li>Details such as transaction amount, bank name, card type, card number.</li>
        </ul>
        <h4>Online Identifiers and Other Technical Data:</h4>
        <ul>
          <li>Location details such as data we get about your location, IP address, logs, or from where you connect a computer to the internet.</li>
          <li>Technical details such as device information, location, and network carrier when you use our mobile applications.</li>
          <li>Communications details such as the metadata and other Personal Data we get from communications done through emails, SMS, instant messages, and calls.</li>
          <li>Usage data details such as data about how you use our website or web-based properties, pages viewed, etc.</li>
        </ul>
        <p>
          We improve our products and advertising by using analytical tools to see how you use our website. By using our site, you agree that we and our partners can collect and use this data.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Consent</h3>
        <p>
          By using the Platform and/or by providing your information, you consent to the collection and use of the information you disclose on the Platform in accordance with this Privacy Policy, including but not limited to your consent for sharing your information as per this privacy policy.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Purposes of Gathering Your Personal Data</h3>
        <h4>Purchase and Delivery of Products and Services:</h4>
        <p>
          We use your personal information to take and handle orders, deliver products and services, process payments, and communicate with you about orders, products and services, and promotional offers.
        </p>
        <h4>Provide, Troubleshoot, and Improve Akiyaras Cosmetics:</h4>
        <p>
          We use your personal information to provide functionality, analyze performance, fix errors, and improve the usability and effectiveness of Akiyaras Cosmetics.
        </p>
        <h4>Recommendations and Personalization:</h4>
        <p>
          We use your personal information to recommend features, products, and services that might be of interest to you, identify your preferences, and personalize your experience with Akiyaras Cosmetics.
        </p>
        <h4>Communicate With You:</h4>
        <p>
          We use your personal information to communicate with you in relation to Akiyaras Cosmetics via different channels (e.g., by phone, email, chat).
        </p>
        <h4>Advertising:</h4>
        <p>
          We use your personal information to display interest-based ads for features, products, and services that might be of interest to you.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Lawful Bases of Processing Your Personal Data</h3>
        <p>
          We are permitted to process your Personal Data in compliance with applicable laws and regulations by relying on one or more of the following lawful bases:
        </p>
        <ul>
          <li>You have explicitly agreed to us processing your Personal Data for a specific reason.</li>
          <li>The processing is necessary to perform the agreement we have with you or to take steps to enter into an agreement with you.</li>
          <li>The processing is necessary to be in compliance with our legal obligations.</li>
          <li>The processing is necessary for the purposes of a legitimate interest (“Legitimate Interest”) pursued by us, such as to provide services to you, to evaluate, develop or improve our products and services.</li>
        </ul>
        <p>
          Where the processing is based on your consent, you have a right to withdraw your consent at any time. You may withdraw consent by contacting us at <a href="mailto:akiyarass@gmail.com">akiyarass@gmail.com</a> or <a href="tel:+918080401501">+91 8080401501</a>. Upon receipt of your written request to withdraw your consent, consequences of withdrawal will be communicated to you and, upon your agreement, your request for withdrawal will be processed.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Cookies and Other Trackers Used by Our Digital Properties</h3>
        <p>
          Cookies are small text files that are placed on your computer by websites that you visit. Similarly, there are other types of trackers used. Likewise, mobile apps use requisite permissions and SDKs. These are used to make websites and apps work, or work more efficiently, as well as to provide information to the owners of the website/app.
        </p>
        <p>
          We use cookies, permissions, and other trackers on our website and mobile applications that are used to collect and process data about you so we can provide you with a better online experience and improve our services.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Personal Data Disclosure</h3>
        <p>
          Our Platform contains third-party SDKs that collect personally identifiable information (PII). They use this information to better target users or provide elements of our products and services on behalf of us. These third-party sites have separate and independent privacy policies.
        </p>
        <h4>Reasons for Disclosing Your Personal Data to Other Parties:</h4>
        <ul>
          <li>Need to provide you with products or services.</li>
          <li>Where you have initiated a payment.</li>
          <li>Have a legal obligation to do so, e.g., to assist with detecting and preventing fraud.</li>
          <li>Have a requirement in connection with regulatory reporting, litigation, or asserting or defending legal rights and interests.</li>
          <li>Have a legitimate business reason for doing so.</li>
          <li>Want to send you information and promotional material regarding our products and services.</li>
          <li>Have asked you for your consent to share it, and you’ve agreed.</li>
          <li>Improve your online experience for using our product.</li>
        </ul>
        <h4>To Whom Your Personal Data May Be Disclosed:</h4>
        <ul>
          <li>Other group companies and any subcontractors, agents, or service providers who work for us or provide services to us or group companies (including their employees, sub-contractors, service providers, directors, and officers).</li>
          <li>Law enforcement authorities, government authorities, courts, dispute resolution bodies, our regulators, auditors, and any party appointed or requested by our regulators to carry out investigations or audits of our activities.</li>
          <li>Statutory and regulatory bodies and authorities (including the government), investigating agencies, and entities or persons to whom or before whom it is mandatory to disclose the Personal Data as per the applicable law, courts, judicial and quasi-judicial authorities and tribunals, arbitrators, and arbitration tribunals.</li>
          <li>Overseas regulators.</li>
          <li>Anybody else that we’ve been instructed to share your Personal Data with by you.</li>
        </ul>
      </div>

      <div className={styles.section}>
        <h3>Data Security</h3>
        <p>
          We are committed to protecting your Personal Data in our custody. We take reasonable steps to ensure appropriate physical, technical, and managerial safeguards are in place to protect your Personal Data from unauthorized access, alteration, transmission, and deletion. We work to protect the security of your personal information during transmission by using encryption protocols. We use multi-layered controls to help protect our infrastructure, constantly monitoring and improving our applications, systems, and processes to meet the growing demands and challenges of security. We ensure that third parties who provide services to us under appropriate contracts take appropriate security measures to protect your Personal Data in line with our policies.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Retention and Disposal of Data</h3>
        <p>
          We keep the Personal Data we collect about you on our systems or with third parties for as long as it is required for the purposes set out in this Privacy Policy and for legal or regulatory reasons. We will only use your Personal Data for those purposes and will make sure that your privacy is protected. We shall take reasonable steps to delete or permanently de-identify Personal Data that is no longer needed, within the overall data retention limit of 180 days.
        </p>
      </div>

      <div className={styles.section}>
        <h3>Notification of Changes</h3>
        <p>
          We keep our Privacy Policy under regular review to make sure it is up to date and accurate. Any changes we may make to this Privacy Policy in the future will be posted on this page. We recommend that you revisit this page regularly to check for any updates.
        </p>
      </div>

      <div className={styles.section}>
        <h3>How to Request Data Deletion</h3>
        <p>
          If you have consented to the collection, use, and/or disclosure of your Personal and Sensitive Information as identified in this Policy, you can access, amend, alter, or request deletion of your information partially or fully by contacting us at <a href="mailto:akiyarass@gmail.com">akiyarass@gmail.com</a> or <a href="tel:+918080401501">+91 8080401501</a>.
        </p>
      </div>

    </div></Layout>
  
  );
}

export default PrivacyPolicy;
