import React, { useEffect, useState } from "react";
import Layout from "../Componets/Layout/Layout";
import axios from "axios";
import './ProductCardStyles.css'
import { useNavigate } from "react-router-dom";
import { useCart } from '../contex/cart';
import toast from "react-hot-toast";
import { MdBolt } from "react-icons/md";
import { useAuth } from '../contex/auth';

const ProductCard = () => {
  const navigate=useNavigate()
  const [products, setProducts] = useState([]);
  const [cart,setCart]=useCart()
  const [auth] = useAuth();
  

  const getAllProducts = async () => {
    try {
      const { data } = await axios.get(`https://backend-sepia-nine-81.vercel.app/api/v1/product/get-product`);
      const trendingProducts = data.products.filter(
        (product) => product.trending === true
      );
      setProducts(trendingProducts.slice(0, 3)); // Only first 3 products
    } catch (error) {
   
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const convert = require('number-to-words');

  function convertPriceToWords(price) {
      // Split the price into rupees and paise
      const [rupees, paise] = price.toString().split('.');
  
      // Convert rupees to words
      const rupeesInWords = convert.toWords(parseInt(rupees, 10));
  
      // Convert paise to words
      const paiseInWords = paise ? `${convert.toWords(parseInt(paise, 10))} Paise` : '';
  
      // Construct the final representation
      return `${rupeesInWords} Rupees ${paiseInWords}`;
  }
  return (
    
    <div className="background">
      <h2 className="bestseller">
        BestSeller
        
      </h2>
      <span className="ultimate">The Ultimate Three</span>
      <p>-----</p>
      <div className="product-container">
        {products.map((product) => (
          <div className="product-card-container" key={product.id}  onClick={() => navigate(`/product/${product.slug}`)}>
            <div className="logo-cart-container">
              <i className="bx bx-shopping-bag" />
            </div>
            <MdBolt  style={{
      fontSize: '40px', // Adjust the size as needed
      color: 'red' // Adjust the color as needed
    }} />
            <div className="main-images-container">
         
              <img
                className="product-image"
                src={`https://backend-sepia-nine-81.vercel.app/api/v1/product/product-photo/${product._id}`}
                alt={product.name.substring(0,10)}
                draggable="false"
                height={50}
              />
            </div>
            <div className="shoe-details-container">
              <span className="shoe-name">{product.name.substring(0,18)}...</span>
              <p>{product.description.substring(0,6)}...</p>
              <div className="stars-container">
                <i className="bx bxs-star" />
                <i className="bx bxs-star" />
                <i className="bx bxs-star" />
                <i className="bx bxs-star" />
                <i className="bx bx-star" />
              </div>
            </div>
            <div className="color-price-container">
              <div className="price-container">
                <span className="price-number">₹{product.price}</span>
                <span className="price-letter">{convertPriceToWords(product.price)}</span>
              </div>
            </div>
            <div className="button-container">
              <button className="add-to-cart-button" onClick={() => {
                      if (!auth.token) {
                        toast.error('Please log in to add items to the cart.');
                        navigate('/login');
                        return;
                      }
                      setCart([...cart, product]);
                      localStorage.setItem(
                        "cart",
                        JSON.stringify([...cart, product])
                      );
                      toast.success("Item Added to cart");
                    }}>Add To Cart</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductCard;
