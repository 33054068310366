import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../Componets/Layout/Layout";
import { useAuth } from "../../contex/auth";
import AdminMenu from "../../Componets/Layout/AdminMenu";
import { Select } from "antd";

const { Option } = Select;

const AdminOrder = () => {
  const [orders, setOrders] = useState([]);
  const [auth] = useAuth();
  const [status] = useState([
    "Not Process",
    "Processing",
    "Shipped",
    "Delivered",
    "Cancel",
  ]);
  const [changeStatus, setChangeStatus] = useState("");

  const fetchOrders = async () => {
    try {
      const response = await axios.get("https://backend-sepia-nine-81.vercel.app/api/v1/orders/all-orders", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setOrders(response.data.orders);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      fetchOrders();
    }
  }, [auth?.token]);

  const handleChange = async (orderId, value) => {
    try {
      await axios.put(`https://backend-sepia-nine-81.vercel.app/api/v1/auth/order-status/${orderId}`, {
        status: value,
      });
      fetchOrders();
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTotalOrderCost = (order) => {
    return order.products.reduce(
      (total, product) => total + (product?.price || 0),
      0
    );
  };

  const calculateGrandTotalCost = (orders) => {
    return orders.reduce(
      (total, order) => total + calculateTotalOrderCost(order),
      0
    );
  };

  return (
    <Layout title="All Orders">
      <div className="container-fluid p-3 m-3">
        <div className="row">
          <div className="col-md-3 mb-3 mb-md-0">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1>All Orders</h1>
            <div className="orders">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Buyer</th>
                      <th>Address</th>
                      <th>Contact</th>
                      <th>Order Date</th>
                      <th>Products</th>
                      <th>Total Cost</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => (
                      <tr key={order._id}>
                        <td>
                          {order.buyer
                            ? `${order.buyer.name} (${order.buyer.email})`
                            : "Unknown Buyer"}
                        </td>
                        <td>
                          {order.buyer
                            ? `${order.buyer.address}`
                            : "Unknown Buyer"}
                        </td>
                        <td>
                          {order.buyer
                            ? `${order.buyer.phone}`
                            : "Unknown Buyer"}
                        </td>
                        <td>{new Date(order.createdAt).toLocaleString()}</td>
                        <td>
                          <ul>
                            {order.products &&
                              order.products.map((product) =>
                                product ? (
                                  <li key={product._id}>
                                    <p>
                                      Product Name: {product.name || "Unknown"}
                                    </p>
                                    <p>Price: ₹{product.price || 0}</p>
                                  </li>
                                ) : (
                                  <li key={Math.random()}>Unknown Product</li>
                                )
                              )}
                          </ul>
                        </td>
                        <td>₹{calculateTotalOrderCost(order).toFixed(2)}</td>
                        <td>
                          <Select
                            bordered={false}
                            onChange={(value) => handleChange(order._id, value)}
                            defaultValue={order?.status}
                          >
                            {status.map((s, i) => (
                              <Option key={i} value={s}>
                                {s}
                              </Option>
                            ))}
                          </Select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="5" className="text-right">
                        <b>Grand Total:</b>
                      </td>
                      <td>
                        <b>₹{calculateGrandTotalCost(orders).toFixed(2)}</b>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminOrder;
