import React, { useState, useEffect } from "react";
import Layout from "../../Componets/Layout/Layout";
import AdminMenu from "../../Componets/Layout/AdminMenu";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banners = () => {
  const [products, setProducts] = useState([]);
  const [banners, setBanners] = useState([]);

  const getAllBanners = async () => {
    try {
      const { data } = await axios.get(
        "https://backend-sepia-nine-81.vercel.app/api/v1/banner"
      );
      const bannersData = data.map((banner) => {
        const base64Flag = `data:${banner.photo.contentType};base64,`;
        const imageStr = arrayBufferToBase64(banner.photo.data.data);
        return {
          image: base64Flag + imageStr,
          name: banner.name,
          slug: banner.slug,
        };
      });
      setBanners(bannersData);
    } catch (error) {}
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    getAllBanners();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Change banner every 2 seconds
  };

  return (
    <Layout>
      <div className="row">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div className="col-md-9">
          <h1 className="text-center">All Banner List</h1>
          <div className="d-flex">
            {/* banner slider */}
            {banners.map((banner, index) => (
              <Link
                key={index}
                to={`/dashboard/admin/banner/${banner.slug}`}
                className="product-link"
              >
                <div
                  style={{
                    display: "inline-block",
                    marginLeft: index !== 0 ? "20px" : "0", // Apply margin-left to all except the first image
                    textAlign: "center", // Center the text below the image
                  }}
                >
                  <img
                    src={banner.image}
                    alt={`Banner ${index + 1}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <p style={{ marginTop: "10px" }}>{banner.name}</p>
                </div>
              </Link>
            ))}
            {/* banner slider */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Banners;
