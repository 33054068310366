import React, { useState } from "react";
import Layout from "../../Componets/Layout/Layout";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contex/auth";
import axios from "axios";
import toast from "react-hot-toast";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://backend-sepia-nine-81.vercel.app/api/v1/auth/login",
        {
          email,
          password,
        }
      );
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(location.state || "/");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout>
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-4 border p-4 rounded">
            <form onSubmit={handleSubmit}>
              <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                Login
              </p>
              <div className="d-flex flex-row align-items-center mb-4">
                <i className="fas fa-envelope fa-lg me-3 fa-fw" />
                <div
                  data-mdb-input-init
                  className="form-outline flex-fill mb-0"
                >
                  <input
                    type="email"
                    id="loginformail"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label className="form-label" htmlFor="loginformail">
                    Your Email
                  </label>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center mb-4">
                <i className="fas fa-lock fa-lg me-3 fa-fw" />
                <div
                  data-mdb-input-init
                  className="form-outline flex-fill mb-0"
                >
                  <input
                    type="password"
                    id="loginformpass"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label className="form-label" htmlFor="loginformpass">
                    Password
                  </label>
                </div>
              </div>
              <div className="form-check d-flex justify-content-center mb-5">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  id="form2Example3c"
                />
                <label className="form-check-label" htmlFor="form2Example3">
                  Remember Me
                </label>
              </div>
              <button type="submit" className="btn btn-primary btn-block mb-3">
                Sign in
              </button>
              <div className="text-center mb-3">
                <NavLink to="/forgot-password" className="text-decoration-none">
                  Forgot password?
                </NavLink>
              </div>
              <div className="text-center mb-4">
                <p>
                  Not a member?{" "}
                  <NavLink to="/register" className="text-decoration-none">
                    Register
                  </NavLink>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
