import React, { useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Layout from "../../Componets/Layout/Layout";

const ForgotPasssword = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [answer, setAnswer] = useState("");

  const navigate = useNavigate();

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://backend-sepia-nine-81.vercel.app/api/v1/auth/forgot-password",
        {
          email,
          newPassword,
          answer,
        }
      );
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);

        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout>
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-4 border p-4 rounded">
            <form onSubmit={handleSubmit}>
              <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                Reset Password
              </p>

              <div className="d-flex flex-row align-items-center mb-4">
                <i className="fas fa-envelope fa-lg me-3 fa-fw" />
                <div
                  data-mdb-input-init
                  className="form-outline flex-fill mb-0"
                >
                  <input
                    type="email"
                    id="resetloginformail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    required
                  />
                  <label className="form-label" htmlFor="resetloginformail">
                    Your Email
                  </label>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center mb-4">
                <i className="fas fa-lock fa-lg me-3 fa-fw" />
                <div
                  data-mdb-input-init
                  className="form-outline flex-fill mb-0"
                >
                  <input
                    type="password"
                    id="resetloginPass"
                    className="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <label className="form-label" htmlFor="resetloginPass">
                    New Password
                  </label>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center mb-4">
                <i className="fas fa-lock fa-lg me-3 fa-fw" />
                <div
                  data-mdb-input-init
                  className="form-outline flex-fill mb-0"
                >
                  <input
                    type="text"
                    id="resetanswer"
                    className="form-control"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    required
                  />
                  <label className="form-label" htmlFor="loginformpass">
                    Your Favorite Sports
                  </label>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-3">
                Reset
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPasssword;
