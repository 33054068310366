import React from "react";
import {  FaWhatsapp } from "react-icons/fa6";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
  return (
    <footer
      className="text-center text-lg-start text-white"
      style={{ backgroundColor: "#1c2331" }}
    >
      {/* Section: Social media */}
      <section
        className="d-flex justify-content-between p-4"
        style={{ backgroundColor: "#6351ce" }}
      >
        {/* Left */}
        <div className="me-5">
          <span>Get connected with us on social networks:</span>
        </div>
        {/* Left */}
        {/* Right */}
        <div>
        
          
         
           
          <NavLink to={`https://wa.me/918080401501`} className="text-white me-4">
           
         { <IoLogoWhatsapp style={{ fontSize: '21px' }} />}
          </NavLink>

          <NavLink to='https://www.instagram.com/reel/C5pg6mHiEiU/' className="text-white me-4">
           
           {<BiLogoInstagramAlt  style={{ fontSize: '21px' }} /> }
         </NavLink>

        </div>
        {/* Right */}
      </section>
      {/* Section: Social media */}
      {/* Section: Links  */}
      <section className>
        <div className="container text-center text-md-start mt-5">
          {/* Grid row */}
          <div className="row mt-3">
            {/* Grid column */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              {/* Content */}
              <h6 className="text-uppercase fw-bold">Akiyaras</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
              />
              <p>
              Welcome to Akiyara's, your number one source for all things beauty and cosmetics. 
              We're dedicated to giving you the very best of our products, with a focus on quality, customer service, and uniqueness.
              </p>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              {/* Links */}
              <h6 className="text-uppercase fw-bold">Products</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
              />
              <p>
                <NavLink to="/allproducts"  className="text-white" style={{ textDecoration: 'none' }}>
                  All Products
                </NavLink>
              </p>
              <p>
                <NavLink to="/trending" className="text-white" style={{ textDecoration: 'none' }}>
                  Trending
                </NavLink>
              </p>
              <p>
                <NavLink to="/upcomming" className="text-white" style={{ textDecoration: 'none' }} >
                  Upcomming
                </NavLink>
              </p>
              
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              {/* Links */}
              <h6 className="text-uppercase fw-bold">Useful links</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
              />
              <p>
                <NavLink to="/dashboard/user/profile" className="text-white" style={{ textDecoration: 'none' }}>
                  Your Account
                </NavLink>
              </p>
              <p>
                <NavLink to="/dashboard/user/profile" className="text-white" style={{ textDecoration: 'none' }}>
                  Profile
                </NavLink>
              </p>
              <p>
                <NavLink to="/dashboard/user/orders"  className="text-white" style={{ textDecoration: 'none' }}>
                  Order
                </NavLink>
              </p>
              <p>
              <NavLink to="/policy"  className="text-white" style={{ textDecoration: 'none' }}>
             
                  Privacy Policy
               
                </NavLink>
              </p>
              <p>
              <NavLink to="/termsandconditions"  className="text-white" style={{ textDecoration: 'none' }}>
             
                  Terms And Condition
               
                </NavLink>
              </p>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              {/* Links */}
              <h6 className="text-uppercase fw-bold" style={{ textDecoration: 'none' }}>Contact</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
              />
              <p>
                <i className="fas fa-home mr-3" /> Shop no- 5, Basement, Pragat Complex, Main Road, Kopargaon, Maharashtra 423601
              </p>
              <p>
                <i className="fas fa-envelope mr-3" /> akiyarass@gmail.com
              </p>
              <p>
                <i className="fas fa-phone mr-3" /> +91 8080401501
              </p>
            
            </div>
            {/* Grid column */}
          </div>
          {/* Grid row */}
        </div>
      </section>
      {/* Section: Links  */}
      {/* Copyright */}
      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
       <p className="text-white">
       All Rights Are Reserved, Develop By Taufiq And Chetan (Contact: <a href="tel:+919156119613" style={{ textDecoration: 'none' }} className="text-white">9156119613</a>, <a style={{ textDecoration: 'none' }} className="text-white" href={"https://wa.me/+918788246176"} target="_blank" rel="noopener noreferrer">8788246176</a>)
       </p>
      </div>
      {/* Copyright */}
    </footer>
  );
};

export default Footer;


