import React from 'react'
import Layout from '../Componets/Layout/Layout'
const Contact = () => {
  return (
    <Layout>
     <h1>Contact</h1>
   </Layout>
  )
}

export default Contact
