import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../Componets/Layout/Layout";
import UserMenu from "../../Componets/Layout/UserMenu";
import { useAuth } from "../../contex/auth";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [auth] = useAuth();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          "https://backend-sepia-nine-81.vercel.app/api/orders",
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setOrders(response.data.orders);
      } catch (error) {}
    };

    if (auth?.token) {
      fetchOrders();
    }
  }, [auth?.token]);

  const calculateTotalOrderCost = (orders) => {
    return orders.reduce((total, order) => {
      const orderTotal = order.products.reduce(
        (orderSum, product) => orderSum + product.price,
        0
      );
      return total + orderTotal;
    }, 0);
  };

  const totalOrderCost = calculateTotalOrderCost(orders);

  return (
    <Layout title="Your Orders">
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-lg-3 col-md-4 mb-3 mb-md-0">
            <UserMenu />
          </div>
          <div className="col-lg-9 col-md-8">
            <h1>Your Orders</h1>
            {orders.length > 0 ? (
              <>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Order Date</th>
                        <th scope="col">Product Image</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Total Cost</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order) => {
                        const totalCost = order.products.reduce(
                          (total, product) => total + product.price,
                          0
                        );
                        return (
                          order.products.length > 0 && (
                            <React.Fragment key={order._id}>
                              {order.products.map((product, index) => (
                                <tr key={product._id}>
                                  {index === 0 && (
                                    <>
                                      <td rowSpan={order.products.length}>
                                        {new Date(
                                          order.createdAt
                                        ).toLocaleString()}
                                      </td>
                                    </>
                                  )}
                                  <td>
                                    <img
                                      src={`https://backend-sepia-nine-81.vercel.app/api/v1/product/product-photo/${product._id}`}
                                      alt={product.name}
                                      className="img-fluid"
                                      width="50"
                                      height="50"
                                    />
                                  </td>
                                  <td>{product.name}</td>
                                  <td>₹{product.price.toFixed(2)}</td>
                                  {index === 0 && (
                                    <td rowSpan={order.products.length}>
                                      ₹{totalCost.toFixed(2)}
                                    </td>
                                  )}
                                  <td>{order.status}</td>
                                </tr>
                              ))}
                            </React.Fragment>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <h2 className="text-right mt-3">
                  Total Order Cost: ₹{totalOrderCost.toFixed(2)}
                </h2>
              </>
            ) : (
              <p>No orders found.</p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Orders;
