import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Componets/Layout/Layout';
import './Faq.css';

const FAQ = () => {
  return (
    <Layout title="FAQs">
      <div className="faq-container">
        <h1 className="faq-title">Frequently Asked Questions</h1>
        <div className="faq-section bg-light">
          <h3>1. How do I create an account on Akiyara's Cosmetics?</h3>
          <p>
          To create an account, click on the "Register" button on our homepage and fill in the required details. Once registered, you can log in using your email and password.          </p>
        </div>
        <div className="faq-section bg-secondary">
          <h3>2. What should I do if I forget my password?</h3>
          <p>
          If you forget your password, click on the "Forgot Password" link on the login page. Follow the instructions to reset your password via email.
          </p>
        </div>
        <div className="faq-section bg-light">
          <h3>3. Who can use the Akiyara's Cosmetics platform?</h3>
          <p>
          The platform is available to individuals capable of forming legally binding contracts under applicable law. Minors under the age of 18 may use the platform under parental supervision.
          </p>
        </div>
        <div className="faq-section bg-secondary">
          <h3>4. How can I contact customer support?</h3>
          <p>
          You can contact our customer support via email or phone. Our contact person is available during specified hours to handle any complaints or inquiries.
          </p>
        </div>
        <div className="faq-section bg-light">
          <h3>5. What payment methods are accepted?</h3>
          <p>
          We accept various payment methods, including UPI, Credit Cards, Debit Cards, Net Banking, Wallets, e-Gift cards, and reward points. Cash On Delivery is available in limited areas.          </p>
        </div>
        <div className="faq-section bg-secondary">
          <h3>6. Are there any order limits?</h3>
          <p>
          Individual buyers may place a maximum of 7 orders or reach a cumulative total payable amount within a single calendar month. Exceeding this limit requires direct contact with Akiyara's Cosmetics.
          </p>
        </div>
        <div className="faq-section bg-light">
          <h3>7. What is the shipping and delivery policy?</h3>
          <p>
          Confirmed orders are shipped within 24 to 48 working hours and delivered within 4-7 working days. Delivery fees may apply based on the order value.
          </p>
        </div>
        <div className="faq-section bg-secondary">
          <h3>8. Can I cancel my order?</h3>
          <p>
          Yes, you can cancel your order. However, Akiyara's Cosmetics reserves the right to cancel orders at its discretion, such as due to pricing errors or fraud activities. Customers will be notified accordingly.
          </p>
        </div>
        <div className="faq-section bg-light">
          <h3>9. How are refunds processed for cancelled orders?</h3>
          <p>
          The platform is available to individuals capable of forming legally binding contracts under applicable law. Minors under the age of 18 may use the platform under parental supervision.
          </p>
        </div>
        <div className="faq-section bg-secondary">
          <h3>10. How can I track my order?</h3>
          <p>
          Once your order is shipped, you will receive a tracking number via email. Use this number on our website or the courier's website to track your order.
          </p>
        </div>
        <div className="text-center mt-5">
          <Link to="/" className="btn btn-dark back-to-home">Back to Home</Link>
        </div>
      </div>
    </Layout>
  );
};

export default FAQ;

