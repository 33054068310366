import React, { useEffect, useState } from "react";
import Layout from "../Componets/Layout/Layout";
import axios from "axios";
import styles from "./HomePage.module.css"; // Import the CSS module
import { FaFireFlameCurved } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductCard from "./ProductCard";

const HomePage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [banners, setBanners] = useState([]);
  const [bannerSecond, setBannerSecond] = useState([]);

  const getAllProducts = async () => {
    try {
      const { data } = await axios.get("https://backend-sepia-nine-81.vercel.app/api/v1/product/get-product");
      const trendingProducts = data.products.filter(
        (product) => product.trending === true
      );
      setProducts(trendingProducts);
    } catch (error) {}
  };

  const getAllBanners = async () => {
    try {
      const { data } = await axios.get("https://backend-sepia-nine-81.vercel.app/api/v1/banner");

      let bannerNamesOne = ["1", "2", "3"];

      // Check screen size and update bannerNamesOne accordingly
      if (window.innerWidth <= 768) {
        bannerNamesOne = ["7", "8", "9"];
      }

      const base64Banners = data
        .filter((banner) => bannerNamesOne.includes(banner.name))
        .map((banner) => {
          const base64Flag = `data:${banner.photo.contentType};base64,`;
          const imageStr = arrayBufferToBase64(banner.photo.data.data);
          return base64Flag + imageStr;
        });

      setBanners(base64Banners);

      // Get the banners with names '4', '5', and '6'
      const bannerNames = ["4", "5", "6"];
      const filteredBanners = data
        .filter((banner) => bannerNames.includes(banner.name))
        .map((banner) => {
          const base64Flag = `data:${banner.photo.contentType};base64,`;
          const imageStr = arrayBufferToBase64(banner.photo.data.data);
          return base64Flag + imageStr;
        });

      setBannerSecond(filteredBanners);
    } catch (error) {}
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    getAllProducts();
    getAllBanners();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Change banner every 2 seconds
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <Layout>
      {/* banner slider */}
      {banners.length > 0 && (
        <Slider {...sliderSettings}>
          {banners.map((banner, index) => (
            <div key={index}>
              <img
                src={banner}
                className={`banner-img ${styles["banner-img"]}`}
                alt={`Banner ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      )}
      {/* banner slider */}

      <div className={styles["container-fluid"]}>
        <h2 className={styles["trending"]}>
          Trending{" "}
          <FaFireFlameCurved style={{ marginLeft: "3px", color: "#ff0000" }} />
        </h2>
        <div className={styles["scrolling-wrapper"]}>
          {products.map((product) => (
            <button
              onClick={() => navigate(`/product/${product.slug}`)}
              className={styles["product-card"]}
              key={product._id}
            >
              <div className={styles["main-images"]}>
                <img
                  id="MDB-logo"
                  src={`https://backend-sepia-nine-81.vercel.app/api/v1/product/product-photo/${product._id}`}
                  alt={product.name.substring(0, 10)}
                  draggable="false"
                  height={25}
                />
              </div>
            </button>
          ))}
        </div>
      </div>

      {/* banner images */}
      <div className={`${styles["container-fluid"]} mt-5`}>
        {bannerSecond.length > 0 && (
          <Slider {...sliderSettings}>
            {bannerSecond &&
              bannerSecond.map((banner, index) => (
                <img
                  key={index}
                  src={banner}
                  className={`banner-img ${styles["banner-img"]}`}
                  alt={`Banner ${index + 1}`}
                />
              ))}
          </Slider>
        )}
        {/* banner images */}
      </div>

      <ProductCard></ProductCard>
    </Layout>
  );
};

export default HomePage;
