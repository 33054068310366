import axios from "axios";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSearch } from "../../contex/search";

const SearchInput = () => {
  const [values, setValues] = useSearch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(
        `https://backend-sepia-nine-81.vercel.app/api/v1/product/search/${values.keyword}`
      );
      setValues({ ...values, results: data });
      navigate("/search");
    } catch (error) {
     
    }
  };
  return (
    <form className="d-flex align-items-center w-100 form-search"  onSubmit={handleSubmit}>
      <div className="input-group">
        <button
          className="btn btn-light dropdown-toggle shadow-0"
          type="button"
          data-mdb-toggle="dropdown"
          aria-expanded="false"
          style={{ paddingBottom: "0.4rem" }}
        >
          All
        </button>
        <ul className="dropdown-menu dropdown-menu-dark fa-ul">
          <li>
            <NavLink className="dropdown-item">
              <span className="fa-li pe-2">
                <i className="fas fa-search" />
              </span>
              All
            </NavLink>
          </li>
          {/* Additional list items */}
        </ul>

        <input
          className="form-control"
          type="search"
          placeholder="Search"
          aria-label="Search"
          value={values.keyword}
          onChange={(e) => setValues({ ...values, keyword: e.target.value })}
        />
      </div>
      <NavLink className="text-white" onClick={handleSubmit}>
    
        <i className="fas fa-search ps-3" />
      </NavLink>
      
    </form>
  );
};

export default SearchInput;
