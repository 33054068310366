import React, { useState, useEffect } from "react";
import Layout from "../Componets/Layout/Layout";
import axios from "axios";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import "./ProductDetails.css"; // Ensure this CSS file is imported
import "bootstrap/dist/css/bootstrap.min.css";
import { useCart } from "../contex/cart";
import toast from "react-hot-toast";

const ProductDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [cart, setCart] = useCart();
  const [isUpcoming, setIsUpcoming] = useState(false);

  useEffect(() => {
    if (params?.slug) getProduct();
  }, [params?.slug]);

  const getProduct = async () => {
    try {
      const { data } = await axios.get(
        `https://backend-sepia-nine-81.vercel.app/api/v1/product/get-product/${params.slug}`
      );
      setProduct(data?.product);
      setIsUpcoming(data?.product.upcoming);
      getSimilarProduct(data?.product._id, data?.product.category._id);
    } catch (error) {}
  };

  const getSimilarProduct = async (pid, cid) => {
    try {
      const { data } = await axios.get(
        `https://backend-sepia-nine-81.vercel.app/api/v1/product/related-product/${pid}/${cid}`
      );
      setRelatedProducts(data?.products);
    } catch (error) {}
  };
  const high = product.price + (product.price % 10);
  return (
    <Layout>
      <section className="py-5">
        <div className="container">
          <div className="row gx-5">
            <aside className="col-lg-6">
              <div className="border rounded-4 mb-3 d-flex justify-content-center">
                <NavLink
                  data-fslightbox="mygalley"
                  className="rounded-4"
                  target="_blank"
                  data-type="image"
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100vh",
                      margin: "auto",
                    }}
                    className="rounded-4 fit"
                    src={`https://backend-sepia-nine-81.vercel.app/api/v1/product/product-photo/${product._id}`}
                  />
                </NavLink>
              </div>
              <div className="d-flex justify-content-center mb-3">
                <NavLink
                  data-fslightbox="mygalley"
                  className="border mx-1 rounded-2"
                  target="_blank"
                  data-type="image"
                >
                  <img
                    width={60}
                    height={60}
                    className="rounded-2"
                    src={`https://backend-sepia-nine-81.vercel.app/api/v1/product/product-photo/${product._id}`}
                  />
                </NavLink>
              </div>
            </aside>
            <main className="col-lg-6">
              <div className="ps-lg-3">
                <h4 className="title text-dark">{product.name}</h4>
                <div className="d-flex flex-row my-3">
                  <div className="text-warning mb-1 me-2">
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fas fa-star-half-alt" />
                    <span className="ms-1">4.5</span>
                  </div>
                  <span className="text-muted">
                    <i className="fas fa-shopping-basket fa-sm mx-1" />
                    {product.quantity}
                  </span>
                  <span className="text-success ms-2">In stock</span>
                </div>
                <div className="mb-3">
                  <span
                    className="h5"
                    style={{ textDecoration: "line-through" }}
                  >
                    ₹{high}
                  </span>
                  <span className="text-muted">&nbsp; &nbsp;  ₹{product.price}</span>
                  <span className="text-muted">/per product</span>
                </div>
                <div className="description-container">
                  <p>{product.description}</p>
                </div>
                <div className="row">
                  <dt className="col-3">Category:</dt>
                  <dd className="col-9">{product?.category?.name}</dd>
                  <dt className="col-3">Type:</dt>
                  <dd className="col-9">Regular</dd>
                </div>
                <hr />
                <button
                  className="btn btn-warning shadow-0 bnbtt"
                  onClick={() => {
                    setCart([...cart, product]);
                    localStorage.setItem(
                      "cart",
                      JSON.stringify([...cart, product])
                    );
                    toast.success("Item Added to cart");
                    navigate("/cart");
                  }}
                  disabled={isUpcoming}
                >
                  Buy now
                </button>
                <button
                  className="btn btn-primary shadow-0"
                  onClick={() => {
                    setCart([...cart, product]);
                    localStorage.setItem(
                      "cart",
                      JSON.stringify([...cart, product])
                    );
                    toast.success("Item Added to cart");
                  }}
                  disabled={isUpcoming}
                >
                  <i className="me-1 fa fa-shopping-basket " /> Add to cart
                </button>
                {isUpcoming && (
                  <div className="alert alert-info mt-3">
                    This product is upcoming and cannot be purchased yet.
                  </div>
                )}
              </div>
            </main>
          </div>
        </div>
      </section>
      <section className="bg-light border-top py-4 d-flex justify-content-center">
        <div className="container">
          <div className="col-lg-8 mb-4"></div>
          <div className="col-lg-12">
            <div className="px-0 border rounded-2 shadow-0">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Similar items</h5>
                  <div className="d-flex flex-column mb-3">
                    {relatedProducts.length < 1 && (
                      <p>No Similar Product Found</p>
                    )}
                    {relatedProducts.map((product) => (
                      <NavLink
                        key={product._id}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/product/${product.slug}`);
                        }}
                        className="custom-navlink"
                      >
                        <div className="d-flex">
                          <a href="#" className="me-3">
                            <img
                              src={`https://backend-sepia-nine-81.vercel.app/api/v1/product/product-photo/${product._id}`}
                              style={{ minWidth: 96, height: 96 }}
                              className="img-md img-thumbnail"
                              alt=""
                            />
                          </a>
                          <div className="info">
                            <a className="nav-link mb-1">
                              <b>{product.name}</b>
                              <br />
                              <b>Description</b>:{" "}
                              {product.description.substring(0, 140)}...
                            </a>
                            <strong className="text-dark">
                              ₹{product.price}
                            </strong>
                          </div>
                        </div>
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductDetails;
