import React from "react";
import "./Header.css";
import Navbar from "./Navbar";
import { useAuth } from "../../contex/auth";
import { NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import SearchInput from "../Form/SearchInput";

const Header = () => {
  const [auth, setAuth] = useAuth();

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfullly");
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <NavLink to="/" className="navbar-brand">
            <img
              id="MDB-logo"
              src="/images/akiyaras.png"
              alt="MDB Logo"
              draggable="false"
              height={50}
            />
          </NavLink>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <SearchInput></SearchInput>
            <ul className="navbar-nav ms-3">
              <li className="nav-item me-3">
                <NavLink
                  to="/about"
                  className="nav-link d-flex align-items-center"
                >
                  About
                </NavLink>
              </li>
              {!auth.user ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      to="/login"
                      className="nav-link d-flex align-items-center me-3"
                    >
                      Login
                    </NavLink>
                  </li>
                  <li className="nav-item" style={{ width: 85 }}>
                    <NavLink
                      to="/register"
                      className="nav-link d-flex align-items-center"
                    >
                      Sign In
                    </NavLink>
                  </li>
                </>
              ) : (
                <>
                  <MDBDropdown group>
                    <MDBDropdownToggle color="dark">
                      {auth?.user?.name}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem link>
                        <NavLink to={`/dashboard/${
                            auth?.user?.role === 1 ? "admin" : "user"
                          }`} className="dropdown-item">
                          Dashboard
                        </NavLink>
                      </MDBDropdownItem>
                      <MDBDropdownItem link>
                        <NavLink
                          onClick={handleLogout}
                          to="/login"
                          className="dropdown-item"
                        >
                          Logout
                        </NavLink>
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>

      <Navbar></Navbar>
    </div>
  );
};

export default Header;
